import React, { useState, useEffect, useCallback }  from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCookies } from 'react-cookie';
import styles from './App.module.css';
import Login, { LoginCookieName } from "./Login";
import logo from './images/nixa_logo_white.png'
import symbol from './images/nixa_symbol.png'
import whitesymbol from './images/nixa_symbol_white.png'
import Profile from './Profile';
import Home from './Home';
import Faq from './Faq';
import "react-toastify/dist/ReactToastify.css";
import fetcher from './infrasctructure/fetcher';

export default function App() {

  const [clientData, setClientData] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [reponseStatus, setReponseStatus] = useState(0);
  const [token, setToken] = useState({});
  const [,,removeCookie] = useCookies('loginInfo');
  
  useEffect(() => {
    if (!token.client) return;
    
    const getClientData = async () => {
      let response;
      try {
        response = await fetcher(`/api/client/`, {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.tokenObj}`
          }
        });
        const json = await response.json();
        setClientData(json);
      } catch (e) {
        if (response.status === 401) {
          setReponseStatus(response.status);
        }
      }
    };
    if (token.tokenObj) {
      getClientData();
    }
  },[token]);

  const logout = useCallback(c => {
    setToken({});
    removeCookie(LoginCookieName, {path: "/"});
  }, [removeCookie]);

  useEffect(() => {
    if (reponseStatus === 401) {
      logout();
    }
  }, [reponseStatus, logout]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const login = loginObj => {
    if (loginObj?.tokenObj !== token.tokenObj) {
      setToken(loginObj);
    }
  };

  const Footer = () => (<footer>
    <div>
      <a href="https://www.nixa.io">
        <img src={logo} className={styles.bottomLogo} alt="nixa logo" />
      </a>
      <p>© 2019 Nixa.io</p>
    </div>
  </footer>);

  if (!token.tokenObj)
    return (
      <>
        <div className={styles.Main}>
          <div className="login">
            <Login login={login} />
          </div>
        </div>
        <Footer/>
      </>
    );
  
  return (
    <Router>
      <div className={menuOpen ? `${styles.menu} ${styles.menuOpen}`: styles.menu}>
        <div className={styles.menuBg}>
          <div className={`${styles.hamburger} ${styles.cross}`} onClick={toggleMenu}>
            <div></div>
          </div>
          <div className={styles.topIcon}>
            <Link to="/">
              <img src={whitesymbol} className={styles.logo} onClick={closeMenu} alt="nixa logo" />
            </Link>
          </div>
          {token.client && <div>
            <Link to="/" onClick={closeMenu}>Home</Link>
          </div>}
          <ul>
            {clientData && clientData.positions.map(p => (
              <li key={p._id}>
                <div className={styles.position}>{p.positionName}</div>
                <ul>
                  {p.shortList.map(s => (
                    <li key={s._id}> 
                      <Link className={s.active ? "" : styles.passive} to={`/profile/${s._id}`} onClick={closeMenu}>{s.presentationName}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
            {token.developer && <>
              <li><Link to={`/profile/`} onClick={closeMenu}>Profile</Link></li>
              <li><Link to={`/faq/`} onClick={closeMenu}>FAQ</Link></li></>
            }
          </ul>
          <a href="/" onClick={logout}>Logout</a>
          <div>&nbsp;</div>

        </div>
        <div className={styles.outsideMenu} onClick={toggleMenu} />
      </div>
      <div className={styles.Main}>
        <header>
          <div className={menuOpen ? `${styles.hamburger} ${styles.menuOpen}` : styles.hamburger} onClick={toggleMenu}>
            <div></div>
          </div>
          <Link to="/">
            <img src={symbol} className={styles.colorlogo} alt="nixa logo" />
          </Link>
          <div>
            <a href="/" onClick={logout}>Logout</a>
          </div>
        </header>
        <Switch>
          <Route path="/profile/:profileId" render={props => (
              <Profile {...props} token={token} />
            )} />
          <Route path="/profile/" render={props => (
              <Profile {...props} token={token} />
            )} />
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/">
            <Home token={token} />
          </Route>

          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </div>
      <Footer/>
      <ToastContainer hideProgressBar />
    </Router>
  );
}

