import React, { useState, useEffect, useCallback } from 'react';
import Image from "./components/Image";
import valid from "./images/valid.png"
import ok from "./images/ok.png"
import fileUrlFor from "./infrasctructure/fileUrlFor";
import Spinner from "./components/Spinner"
import CheckBox from "./components/CheckBox"
import styles from './Profile.module.css';
import FsLightbox from 'fslightbox-react';
import BookInterview from './components/BookInterview';
import ReportChange from './components/ReportChange';
import Summary from './components/Summary';
import Availability from './components/Availability';
import SoMe from './components/SoMe';
import saved from "./images/saved.png";
import fetcher from './infrasctructure/fetcher';

export default function Profile({match, token}) { 

  const [profile, setProfile] = useState(null);
  const [savingActive, setSavingActive] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [reponseStatus, setReponseStatus] = useState(0);
  const [showOk, setShowOk] = useState(false);
  
  const get = useCallback(async id => {
    const getProfile = async (id) => {
      id = id ?? '';
      const response = await fetcher(`/api/profile/${id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.tokenObj}`
        }
      });
  
      if (response.status === 401) {
        setReponseStatus(response.status);
      }
      const json = await response.json();
      setProfile(json);
    };
    getProfile(id);
  },[token.tokenObj]);


  useEffect(() => {
    get(match.params.profileId);
  },[match, token, get]);

  const setActive = async () =>{
    setShowOk(false);
    setSavingActive(true);
    setProfile({...profile, active: !profile.active});
    await fetcher(`/api/profile/setActive/${!profile.active}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.tokenObj}`
      }
    });
    await get();
    setSavingActive(false);
    setShowOk(true);
    setTimeout(() => { setShowOk(false)}, 1000);
  }
  if (!profile) {
    return <Spinner />
  }
  if (reponseStatus === 401) {
    return <>Forbidden</>
  }

  return <div className="container">
    <div className={styles.head}>
      <h1 title="The candidate's first name">{token.developer ? 'Hi' : 'Meet'} {profile.presentationName}</h1>
      {token.developer &&    
        <div className={styles.dev}>      
          <div className={styles.active}>
            <label htmlFor="active">Looking for opportunities:</label>  
            <CheckBox id="active" value={profile.active} className={styles.checkBox} onChange={setActive} />
            {savingActive && <div className={styles.spinner}><Spinner small={true} /></div>}
            {showOk && <div className={styles.ok}><img src={saved} alt='saved' /></div>}
          </div>
        </div>}
    </div>
    <hr/>
    {token.developer && !profile.active && 
      <div className={styles.passiveProfile}>
        <div>Your profile is set inactive and you will not be presented to clients.<br/><br/></div>
      </div>}
    {token.client && !profile.active && 
      <div className={styles.passiveProfile}>
        <div>This profile is currently not available for hire.<br/><br/></div>
      </div>}      
    <div className={styles.top}>
      <div className={styles.topLeft}>
        <div className={styles.image}>
          { profile.image &&
            <Image alt="profile" src={profile.image} />
          }
        </div>
        <div className={styles.info}>
          <div><b>Location<div className="tooltip"><div className="tooltiptext">The candidate's current location and time zone.</div></div>:</b> {profile.location}</div>
          <div>
            <b>Years of Experience
              <div className="tooltip"><div className="tooltiptext">The candidate's years of professional experience.</div></div>
              :</b> {profile.yearsExperience}
          </div>
          <div>
            <b>University Degree
              <div className="tooltip"><div className="tooltiptext">Whether the candidate has achieved a formal degree or not.</div></div>
            :</b> {profile.universityDegree?"Yes":"No"}
          </div>
          <div>
            <b>Remote Experience
            <div className="tooltip"><div className="tooltiptext">Whether the candidate has professional experience working remotely or not.</div></div>
              :
            </b> {profile.remoteExperience?"Yes":"No"}
          </div>
          <div>
            <b>Salary Expectations
            <div className="tooltip"><div className="tooltiptext">The candidate's salary expectations on a yearly basis. This amount is often negotiable.</div></div>
              :</b> {profile.salaryExpectations}
          </div>
          {token.client && <>
            <b>Available within
            <div className="tooltip"><div className="tooltiptext">The candidate's availability to start a new job or project. In some cases, this could be negotiable.</div></div>
              :</b> <Availability token={token} profile={profile} /><br/>
            <SoMe token={token} profile={profile} />
            </>
          }
          {token.developer &&  <ReportChange profile={profile} token={token} /> }
        </div>
      </div>
      {profile.active && token.client && <div className={styles.book}><BookInterview profile={profile} token={token} /></div>}
      {token.developer && <div className={styles.dev}>
        <Availability token={token} profile={profile} /><br/>
        <SoMe token={token} profile={profile} />
      </div>}
    </div>
    {!token.developer && profile.summary && <> 
      <hr/>
      <h3>Summary</h3>
      <p>
        {profile.summary}
      </p>
    </>}
    {token.developer && <Summary profile={profile} token={token} />}

    <hr/>
    <div>
      <h3>Skills
      <div className="tooltip"><div className="tooltiptext">List of technologies highlighted from the candidate's profile. These skills reflect technologies that the candidate prefers to work with.</div></div>
      </h3>
      <div className={styles.skillSection}>
        <img alt="Skills" src={ok} />
        <ul className={styles.skills}>
          {profile.skills && profile.skills.map(i => (
            <li key={i}>{i}</li>
          ))}
        </ul>
      </div>
      {token.developer &&  <div><ReportChange profile={profile} buttonText="Update skills" token={token} /></div> }
    </div>
    <hr/>
    <div>
      <h3>Test result
        <div className="tooltip"><div className="tooltiptext">The candidate's score on Nixa.io's technical test. Name of test states which type of test that is completed (Front-end/Full-stack/Back-end).</div></div>
      </h3>
      <div className={styles.test}>
          <img alt="Nixa approved" src={valid} />
          <div className={styles.testName}>{profile.testName}</div> 
          <div className={styles.testScore}>{profile.testScore}% </div>
          
          {profile.testUrl &&
            <div><a className={styles.testUrl} href={profile.testUrl} target="_blank">View report</a></div>
          }
          {profile.testReport && profile.testReport.asset && <>
            <button className={styles.testReport} onClick={ () => setToggler(!toggler) }>
              View report
            </button>
          <FsLightbox
            toggler={ toggler }
            sources={ [
              <iframe src={fileUrlFor(profile.testReport)} title="test report" width="1680px" height="1680px" allowFullScreen />
            ] }
            /></>}
      </div>
    </div>
    {!token.developer && profile.notes && <> 
      <hr/>
      <h3>Notes from Nixa.io</h3>
      <p>
        {profile.notes}
      </p>
    </>}
    {profile.cv && profile.cv.asset &&
      <>
        <hr/>
        <div>
          <h3>CV
            <div className="tooltip"><div className="tooltiptext">The candidate's full CV. The CV is provided by the candidate.</div></div>
          </h3>
          {token.developer &&  
            <a className={styles.updateCV} href="mailto:platform@nixa.io?subject=Update my CV">Send us an updated CV</a>}
          <iframe className={styles.iframe} title="CV" src={fileUrlFor(profile.cv)} />
        </div>
      </>
    }
  </div>;
}
