import React, {useState} from 'react';
import Spinner from './Spinner';
import styles from './Availability.module.css';
import saved from '../images/saved.png';
import fetcher from '../infrasctructure/fetcher';

const Availability = ({ token, profile }) => {
  const [savingAvailability, setSavingAvailability] = useState(false);
  const [showOk, setShowOk] = useState(false);

  const weeks = [
    {id: 0, val: '0 - 2 weeks'},
    {id: 2, val: '2 - 4 weeks'},
    {id: 4, val: '4 - 8 weeks'},
    {id: 8, val: '8 - 12 weeks'},
    {id: 12, val: '12 weeks or more'},
  ];

  const saveAvailability = async (e) =>{
    let availability = e.target.value;
    setSavingAvailability(true);
    await fetcher(`/api/profile/setAvailability/${availability}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.tokenObj}`
      }
    });
    setSavingAvailability(false);
    setShowOk(true);
    setTimeout(() => { setShowOk(false)}, 1000);
  }
  if (token.client) {
    return (weeks.find(e => e.id === profile.availability)?.val??'Unknown');
  }

  return (<div className={styles.availability}>
      <label htmlFor="changeAvailability">Available within
        <div className="tooltip"><div className="tooltiptext">Your availability to start a new job or project.</div></div>
      :</label> 
      <select id="changeAvailability" className={styles.changeAvailability} onChange={saveAvailability} defaultValue={profile.availability}>
        {weeks.map(w => (
          <option key={w.id} value={w.id}>{w.val}</option>
        ))}
      </select>
      {savingAvailability && <div className={styles.spinner}><Spinner small={true} /></div>}
      {showOk && <div className={styles.ok}><img src={saved} alt='saved' /></div>}
    </div>
  );
};

export default Availability;
