import React from "react";
import { Redirect } from 'react-router-dom';
import styles from './Home.module.css';

export default function Home({token}) {
  if (token.client) { 
    
    return <div className={`container ${styles.home}`}>
      <h1>Hi {token.name}</h1>
      <hr />
      <p>Welcome to our recruitment platform. Here you will find a shortlist of candidates that we 
        have handpicked from our talent pool to specifically fit your needs. These candidates are only 
        available to you for <b>48 hours</b> - so it’s important that you set aside time to review the profiles 
        and request meetings with those that you find interesting. You can view each candidate in detail 
        and navigate between profiles by using the menu on the left. </p>
      <p>Please note that: </p>
      <ul>
        <li>
          Interview requests will not be sent to the candidates directly, but to the Nixa.io team. We will 
          reach out to the candidates to present the company and the role before setting up the interview. 
          Your customer success manager will keep you updated and take charge of the interview scheduling process. 
        </li>
        <li>
          You only have access to the candidates in your shortlist for 48 hours. After 48 hours, you will not be 
          able to book meetings/interviews with these candidates directly. You can still request access to their 
          profile by contacting the Nixa.io team, but we strongly recommend you book meetings with interesting 
          candidates as soon as possible.
        </li>
        <li>
          It’s recommended to book interviews with at least 4 candidates. Based on our experience, it’s always 
          valuable to have a group of candidates that you can compare. There is also a risk that candidates can 
          drop out of the process along the line of interview. To increase the odds of a quality hire in the end 
          - it’s better to invite a higher number of candidates to the first interview. 
        </li>
      </ul>
      <p>
        We hope that you will find great interest in some of the candidates that we have presented. If you have any 
        questions, feel free to reach us by using the chat in the right corner or by sending us an  
        email <a href="mailto:platform@nixa.io">platform@nixa.io</a> / <a href="mailto:kristine@nixa.io">kristine@nixa.io</a> 

      </p>
    </div>
  }
  else if (token.developer) {
    return <Redirect to="/profile" />
  }
}
