import React, { useState, useEffect } from 'react';
import useDebounce from '../infrasctructure/use-debounce';
import Spinner from "./Spinner";
import styles from './SoMe.module.css';
import { toast } from "react-toastify";
import saved from "../images/saved.png";
import fetcher from '../infrasctructure/fetcher';

const SoMe = ({ token, profile }) => {
  const [savingGithub, setSavingGithub] = useState(false);
  const [showOk, setShowOk] = useState(false);
  const [username, setUsername] = useState(profile.github);
  const [savedUsername, setSavedUsername] = useState(profile.github);

  const debouncedUsername = useDebounce(username, 500);

  useEffect(() => {
    const saveGithub = async (github) => {
      setSavingGithub(true);
      setShowOk(false);
      let res = await fetcher(`/api/profile/setGithub/${github}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.tokenObj}`
        }
      });
  
      if (!res.ok) {
        toast(`An error happened please send an email to hello@nixa.io and we will try to help you.`, {
          autoClose: 10000,
          className: styles.toast,
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR
        });
      }
      setSavingGithub(false);
      setShowOk(true);
      setTimeout(() => { setShowOk(false)}, 1000);
    }

    if (debouncedUsername === savedUsername) return;

    let expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);
    if (debouncedUsername?.match(regex)) {
      let exp = /([a-z0-9_-]*[/]?)$/;
      let name = debouncedUsername.match(exp)[0];
      setUsername(name.replace('/',''));
      return;
    }
    saveGithub(debouncedUsername);

    setSavedUsername(debouncedUsername);
  }, [debouncedUsername, savedUsername, token.tokenObj]);

  if (token.client && !profile.github) {
    return null;
  }

  if (token.client) {
    return (profile.github && <>
      <b>Github
      <div className="tooltip"><div className="tooltiptext"> Link to the candidate's Github profile.</div></div>
        : </b> 
      <a href={`https://github.com/${profile.github}`}>{profile.github}</a><br/>
    </>);
  }

  return (<><div className={styles.soMe}>
      <label htmlFor="changeGithub">Github username
        <div className="tooltip"><div className="tooltiptext">Set your username at Github.</div></div>
      :</label>
      <input id="changeGithub" type="text" value={username} onChange={e => setUsername(e.target.value)} /> <br/>
      {savingGithub && <div className={styles.spinner}><Spinner small={true} /></div>}
      {showOk && <div className={styles.ok}><img src={saved} alt='saved' /></div>}
    </div>
    {username && <div className={styles.link}>
      <a href={`https://github.com/${username}`}>Open github</a>
  </div>}
  </>
  );
};

export default SoMe;
