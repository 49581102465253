import React, {useState} from 'react';
import { toast } from "react-toastify";
import styles from './BookInterview.module.css';
import Countdown from 'react-countdown-now';
import Spinner from "./Spinner"
import fetcher from '../infrasctructure/fetcher';

const renderer = ({ days, hours, minutes, seconds }) => {
  return <div className={styles.clockdiv}>
    <div>
      <span className="days">{days}</span>
      <div className={styles.smalltext}>{days !== 1 ? "Days" : "Day" }</div>
    </div>
    <div>
      <span className="hours">{hours}</span>
      <div className={styles.smalltext}>{hours !== 1 ? "Hours" : "Hour" }</div>
    </div>
    <div>
      <span className="minutes">{minutes}</span>
      <div className={styles.smalltext}>{minutes !== 1 ? "Minutes" : "Minute" }</div>
    </div>
    <div>
      <span className="seconds">{seconds}</span>
      <div className={styles.smalltext}>{seconds !== 1 ? "Seconds" : "Second" }</div>
    </div>
  </div>;
};

const BookInterview = ({ profile, token }) => {
  const [toggler, setToggler] = useState(false);
  const [msg, setMsg] = useState("");
  const [booking, setBooking] = useState(false);

  const book = async () => {
    setBooking(true);
    let res = await fetcher(`/api/profile/bookMeeting/${profile._id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.tokenObj}`
      },
      body: JSON.stringify(msg)
    });
    setMsg('');
    setBooking(false);
    setToggler(!toggler);

    if (res.ok) {
      toast(`Thanks for your request. We will try to schedule a meeting for you.`, {
        autoClose: 3000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.INFO
      });
    } else {
      toast(`An error happened please send an email to hello@nixa.io and we will try to help you schedule a meeting.`, {
        autoClose: 10000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR
      });
    }
  };

  const date = new Date(profile.positions.map(function(e) { return e.shortListExpiration; }).sort().reverse()[0]);
  if (date < new Date()) return (<>
    <div className={styles.countDown}>
      <Countdown date={date} renderer={renderer} />
      <div className={styles.schedule}>left to schedule a meeting with this candidate</div> 
    </div>
    <div className={styles.bookingTimedOut}>
      Unfortunately, you are not able to book this candidate for an interview since your 48 hour period is out. 
      If you still want to book this candidate, you can reach out to the Nixa.io team directly on 
      <a href="mailto:platform@nixa.io"> platform@nixa.io</a>
    </div>
  </>);
  return (<>
    <>{!toggler && !isNaN(date) && 
      <div className={styles.countDown}>
        <Countdown date={date} renderer={renderer} />
        <div className={styles.schedule}>left to schedule a meeting with this candidate</div> 
      </div>
    }</>
    {!toggler && <input type="button" className={styles.bookButton} value="Book now" onClick={ () => setToggler(!toggler) } />}
    {toggler && <div className={styles.booking}>
      <p>Please let us know what you liked about this candidate's profile. 
        We'll reach out to book the interview as soon as you confirm the request</p> 
      <textarea value={msg} onChange={(e)=> setMsg(e.target.value)} />
      {!booking && <><input type="button"value="Confirm booking" onClick={ book } />
        &nbsp;<a href="/#" onClick={ (e) => {e.preventDefault(); setToggler(!toggler) }}>Cancel</a>
      </>}
      {booking && <div className={styles.spinner}>
        <Spinner small={true} />
      </div>}
    </div>}
  </>);
};

export default BookInterview;
