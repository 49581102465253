import React, {useState} from 'react';
import { toast } from "react-toastify";
import styles from './Summary.module.css';
import Spinner from "./Spinner"
import fetcher from '../infrasctructure/fetcher';

const Summary = ({ profile, token }) => {
  const [toggler, setToggler] = useState(false);
  const [msg, setMsg] = useState(profile.summary);
  const [summary, setSummary] = useState(profile.summary);
  const [change, setChange] = useState(false);

  const changeSummary = async () => {
    setChange(true);
    let res = await fetcher(`/api/profile/changeSummary/${profile._id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.tokenObj}`
      },
      body: JSON.stringify(msg)
    });
    setChange(false);
    setToggler(!toggler);
    setSummary(msg);

    if (res.ok) {
      toast(`Your summary has been updated`, {
        autoClose: 3000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.INFO
      });
    } else {
      toast(`An error happened please send an email to hello@nixa.io and we will try to help you change your profile.`, {
        autoClose: 10000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR
      });
    }
  };

  return (<div>
    <hr/>
    <h3>Summary</h3>
    {!toggler && <>
      <p>{summary || "No summary set."}</p> 
      <a href="/profile" className={styles.changeButton} onClick={ (e) => {e.preventDefault(); setToggler(!toggler)} } >
      Change summary</a></>}
      {toggler && <div className={styles.change}>
      <textarea value={msg} maxLength={500} onChange={(e)=> setMsg(e.target.value)} />
      {!change && <><input type="button" className={styles.report} value="Save summary" onClick={ changeSummary } />
      &nbsp;<a href="/#" onClick={ (e) => {e.preventDefault(); setToggler(!toggler); setMsg(profile.summary) }}>Cancel</a>
      </>}
      {change && <div className={styles.spinner}><Spinner small={true} /></div>}
    </div>}
  </div>);
};

export default Summary;
