import React, {useState} from 'react';
import { toast } from "react-toastify";
import styles from './ReportChange.module.css';
import Spinner from "./Spinner"
import fetcher from '../infrasctructure/fetcher';

const ReportChange = ({ profile, token, buttonText, sendText, text }) => {
  const [toggler, setToggler] = useState(false);
  const [msg, setMsg] = useState("");
  const [change, setChange] = useState(false);

  const report = async () => {
    setChange(true);
    let res = await fetcher(`/api/profile/reportChange/${profile._id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.tokenObj}`
      },
      body: JSON.stringify(msg)
    });
    setMsg('');
    setChange(false);
    setToggler(!toggler);

    if (res.ok) {
      toast(`Thanks for your request. We will try to accommodate your change request`, {
        autoClose: 3000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.INFO
      });
    } else {
      toast(`An error happened please send an email to hello@nixa.io and we will try to help you change your profile.`, {
        autoClose: 10000,
        className: styles.toast,
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR
      });
    }
  };

  return (<div>
    {!toggler && <a href="/profile" className={styles.changeButton} onClick={ (e) => {e.preventDefault(); setToggler(!toggler)} } >
      {buttonText||"Change information"}</a>}
    {toggler && <div className={styles.change}>
      <p>{text || "Please let us know what you would like to change and what you would like to change it to:"}</p> 
      <textarea value={msg} onChange={(e)=> setMsg(e.target.value)} />
      {!change && <><input type="button" className={styles.report} value={sendText||"Send request"} onClick={ report } />
        &nbsp;<a href="/#" onClick={ (e) => {e.preventDefault(); setToggler(!toggler) }}>Cancel</a>
      </>}
      {change && <div className={styles.spinner}><Spinner small={true} /></div>}
    </div>}
  </div>);
};

export default ReportChange;
