import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import Spinner from "./components/Spinner";
import styles from './Login.module.css';
import fetcher from './infrasctructure/fetcher';

const LoginCookieName = "loginInfo";
export { LoginCookieName };

const Login = ({ login }) => {
  const [state, setState] = useState({
    emailSent: false,
    errorSendingEmail: false,
    passwordError: false,
    email: "",
    password: ""
  });
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [cookies, setCookie] = useCookies([LoginCookieName]);

  useEffect(() => {
    const checkCookie = () => {
      let cookie = cookies[LoginCookieName];
      login({
        tokenObj: cookie?.token, 
        role: cookie?.role, 
        name: cookie?.name,
        email: cookie?.email,
        developer: cookie?.role?.toLowerCase() === 'developer',
        client: cookie?.role?.toLowerCase() === 'client',
      });
    };
    checkCookie();
  }, [cookies, login]);

  const sendEmail = async e => {
    e.stopPropagation();
    e.preventDefault();
    setLoadSpinner(true);
    let res = await sendEmailToServer(state.email);
    if (res.ok) {
      setState({...state, emailSent: true });
    } else {
      setState({...state, errorSendingEmail: true });
    }
    setLoadSpinner(false);
  };

  const sendEmailToServer = async (message) => {
    let res = await fetcher(`/api/sendEmail`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(message)
    });
    return res;
  };

  const fetchToken = async (email, password) => {
    const response =  await fetcher(`/api/login`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify({
        email: email,
        password: password
      })
    });
    if (response.ok) {
      const res = await response.json();
      return res;
    }
    else {
      setState({...state, passwordError: true});
    }
  };

  const getLoginToken = async e => {
    e.preventDefault();
    e.stopPropagation();
    setLoadSpinner(true);
    let res = await fetchToken(state.email, state.password);
    if (res) {
      setCookie(LoginCookieName, res, { maxAge: 31622400, path: '/', sameSite: 'strict'});
      login({
        tokenObj: res?.token, 
        role: res?.role, 
        name: res?.name,
        email: res?.email,
        developer: res?.role?.toLowerCase() === 'developer',
        client: res?.role?.toLowerCase() === 'client',
      });
    };
    setLoadSpinner(false);
  };

  useEffect(()=> {
    const fetchTokenWithUrlToken = async (inputToken) => {
      const response =  await fetcher(`/api/tokenlogin`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${inputToken}`
        },
        method: "post",
        body: "login"
      });
  
      if (response.ok) {
        const res = await response.json();
        return res;
      }
      else {
        setState({...state, passwordError: true});
      }
    };

    const getLoginUrlToken = async (token) => {
      setLoadSpinner(true);
      let res = await fetchTokenWithUrlToken(token);
      if (res) {
        setCookie(LoginCookieName, res, { maxAge: 31622400, path: '/', sameSite: 'strict'});
        login({
          tokenObj: res?.token, 
          role: res?.role, 
          name: res?.name,
          email: res?.email,
          developer: res?.role?.toLowerCase() === 'developer',
          client: res?.role?.toLowerCase() === 'client',
        });
      };
      setLoadSpinner(false);
    };
  
    let search = window.location.search;
    if (!search.includes('token')) return;

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    getLoginUrlToken(token);
  },[login, setCookie, state]);

  if (loadSpinner) {
    return (<Spinner />);
  }

  if (state.emailSent) {
    return (
        <div className={styles.login}>
          <h1>Nixa candidate platform</h1>
          <h3>Sign in</h3>
          {state.passwordError && <div className={styles.error}>Error logging in</div>}
          <form onSubmit={getLoginToken}>
            <label htmlFor="email">Email: </label>
            <input id="email" value={state.email} type="email" onChange={e => setState({...state, email: e.target.value})} ></input><br/>
            <label htmlFor="pwd">One time password from email: </label>
            <input id="pwd" value={state.password} type="password" onChange={e => setState({...state, password: e.target.value})} ></input><br/>
            <button type="submit">Submit</button>
          </form>
        </div>

    );
  }

  return (
      <div className={styles.login}>
        <h1>Nixa candidate platform</h1>
        <h3>Sign in</h3>
        <form onSubmit={sendEmail}>
          <label htmlFor="email">Email: </label>
          <input id="email" value={state.email} type="email" onChange={e => setState({...state, email: e.target.value})} ></input><br/>

          <button type="submit">Continue</button>
        </form>
      </div>
  );
};

export default Login;
