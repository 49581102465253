import React from 'react';
import imageUrlFor from "../infrasctructure/imageUrlFor";

const Image = ({ alt, src }) => {
  return (
    <img alt={alt} src={imageUrlFor(src).width(200).height(200).fit('max').url()} />
  );
};

export default Image;
