import React from 'react';
import styles from './CheckBox.module.css';

const CheckBox = ({ value, onChange, id, className }) => {
  return (<span className={className}>
          <label className={styles.switch} htmlFor={id}>
            <input id={id} type="checkbox" checked={value} onChange={onChange}  />
            <div className={`${styles.slider} ${styles.round}`}></div>
          </label>
        </span>);
};

export default CheckBox;
