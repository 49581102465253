import React from "react";
import styles from './Faq.module.css';

export default function Faq() {

    return <div className={`container ${styles.faq}`}>
      <ol>
        <li>
          <b>What can I expect to find on this platform?</b>
          <div className={styles.spacer} />
          On this platform, you will find your own platform profile and all of the information that we present to companies on your behalf. Your profile is built on the information that you have provided throughout the pre-qualification process. We want to give you a chance to review your profile before it becomes available for companies and an opportunity to update your availability. This platform is currently an MVP under development, so we're hoping to have more functionality ready for you soon.
        </li>
        <li>
          <b>How can I change my profile?</b>
          <div className={styles.spacer} />
          As a candidate, you are only allowed to change some elements of the profile yourself. This concerns whether you want your profile to be active or not and your availability to start a new job or project. You are also allowed to add a link to your Github profile. Please see instructions to change/update these below:
          <div className={styles.spacer} />
          <i>Profile status:</i>
          <div className={styles.spacer}/>
          In order to be presented to companies that are looking to hire through Nixa.io, it is important that the toggle switch on your profile is turned ON. If the switch is turned OFF, your profile will not be available for companies.
          <div className={styles.spacer}/>
          <i>Availability:</i>
          <div className={styles.spacer}/>
          It’s your own responsibility to make sure that your availability to start a new job or project is updated at all times. Knowing when a candidate is available to start a new job is crucial information for companies that are looking to hire and an updated availability will increase your chances of getting interview requests. Use the dropdown menu to set the correct number of weeks until you can start a new job or project. The information will be automatically added to your profile.
          <div className={styles.spacer}/>
          <i>Github:</i>
          <div className={styles.spacer}/>
          If you want to add a link to your Github profile, just type in your Github username in the text field. This is the username that is shown after the <code>www.github.com/</code> in the URL. The information will be automatically added to your profile.
        </li>
        <li>
          <b>What about the information that I'm not able to change myself?</b>
          <div className={styles.spacer} />
          If you want to change any other information on your profile, please use one of the buttons on your profile to request a change. Your request will then be sent to our team and we will make sure to update it accordingly. We will reach out with a confirmation once the update is done. If you want to update your CV, we will reach out by email to collect the new version. 
          Feel free to drop us an email (<a href="mailto:platform@nixa.io">platform@nixa.io</a>) if you have any questions or requests that we can assist you with.
        </li>
        <li>
          <b>If I'm just passively looking for a remote opportunity, should I then turn the toggle wish ON or OFF?</b>
          <div className={styles.spacer} />
          If you are actively or passively looking for a new opportunity, we kindly ask you to make sure that the toggle switch is turned ON. The switch should only be turned off when you are unavailable or uninterested in a new opportunity at the moment. This also counts if you are studying or involved in other commitments that prevent you from taking on a new full-time job over the next few months.
        </li>
        <li>
          <b>How often should I make sure to update my profile?</b>
          <div className={styles.spacer} />
          We recommend you to check in once a week or twice a month to make sure that your profile is updated and correct. Having an updated profile will increase the chances of interview requests from companies.
        </li>
        <li>
          <b>What are you using my profile for and how does this matchmaking process work?</b>
          <div className={styles.spacer} />
          Your profile is continuously matched towards remote opportunities that fit your skills and preferences. When your profile is matched towards a company, we give the hiring manager 48 hours to review your profile and make a decision on whether to set up an interview or not. If the companies show interest in your profile - we will reach out to present you with more information about the company and the role. We care about transparency and will always strive to present you with all the necessary information upfront. It’s then up to you to accept or decline the interview request based on the information you have available. If you accept the invite, we will set up the first interview with the company’s CTO as soon as possible.<br/><br/>
          Note that your profile is only available for the individual company for 48 hours, but it might be set available again if the company requests it.
        </li>
        <li>
          <b>Is there any place where I can see pending interview requests from companies?</b>
          <div className={styles.spacer} />
          Unfortunately not. This is on our roadmap for the continued development of the platform and we hope to have it ready soon. You will always receive an email from us if a company shows interest in your profile.
        </li>
        <li>
          <b>Is there any place that I can leave feedback or ask questions?</b>
          <div className={styles.spacer} />
          Yes, you can always reach us by using the chat in the right corner or by sending an email (<a href="mailto:platform@nixa.io">platform@nixa.io</a>).
        </li>

      </ol>
    </div>
}
